import React, {useEffect,useState} from 'react';
import '../css/login.css';
import UpgradePassword from '../upgradePassword';
import ResetPassword from './resetPassword';
import image from '../assets/icons/funnel.png';
import { useDispatch } from 'react-redux';
import { setUserData } from '../features/agentSlice';
import { fetchWithDefaults } from '../config/fetchDefault';


function LoginPage({agentPw, setUpdatePassword, saveData, loginError, setLoginError, setSignedIn, setAgentIdNumber, agentUsername, updatePassword, setAgentUsername, setAgentPw, loginUser}) {
window.location.replace('https://funnel.quility.com');
    const [newPassword, setNewPassword] = useState(false);
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const passwordReset = params.get('resetCode');
    if(passwordReset){
        const validateReset= async (code)=>{
            try {
                const response = await fetchWithDefaults(`/validateReset?code=${code}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                if(jsonData.status){
                    //console.log(jsonData.agent);
                    setUpdatePassword(true);
                    setAgentUsername(jsonData.agent);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        validateReset(passwordReset);
    }
    const decrypt = async (string) => {
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    cipher: string
                }) 
            };

            const response = await fetchWithDefaults('/decrypt', requestOptions);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const jsonData = await response.json();
            let admin=false;
            if(jsonData[0].admin===1){
                admin=true;
            } 
            dispatch(setUserData({
                agentId: jsonData[0].id,
                organization: jsonData[0].organization,
                admin:admin,
                organizationName:jsonData[0].orgName,
                permissions:jsonData[0].permissions
            }))
            setAgentIdNumber(jsonData[0].id);
            setSignedIn(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const loadData = async () => {
        const storedData = localStorage.getItem('keepMeAlive');
        if(storedData){
            decrypt(storedData)
        }
    };
    const handleResetPassword=()=>{
        setNewPassword(!newPassword);
    }
    useEffect(() => {
        loadData();
    },[])
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loginUser();
        }
    };
    return (
        <div className="mainBackground">
        {newPassword && (
            <ResetPassword handleResetPassword={handleResetPassword}/>
        )}
            <>
                {updatePassword && (
                    <UpgradePassword saveData={saveData} setSignedIn={setSignedIn} setAgentIdNumber={setAgentIdNumber} agentUsername={agentUsername} />
                )}
                
                <div className="logo"><img src={image} className="imageStyle" alt="Logo" /></div>
    
                <div className="loginWrapper">
                    <div className="error" style={{ color: '#ff4949', fontWeight: '400', fontSize: '18px' }}>{loginError}</div>
                    <div className="pageTitle">Agent Login</div>
                    <div className="errorHolder"></div>
                    <div className="usernameWrapper">
                        <input
                            type="text"
                            className="inputBox"
                            placeholder="User Name"
                            value={agentUsername}
                            onFocus={() => setLoginError(false)}
                            onChange={(e) => setAgentUsername(e.target.value)}
                        />
                    </div>
                    <div className="passwordWrapper">
                        <input
                            type="password"
                            className="inputBox"
                            value={agentPw}
                            onFocus={() => setLoginError(false)}
                            onChange={(e) => setAgentPw(e.target.value)}
                            placeholder="Password"
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                    <div className="forgotPasswordWrapper"><span className="forgotPassword" onClick={()=> handleResetPassword()}>Forgot your password?</span></div>
                    <div className="forgotPasswordWrapper"><a className="forgotPassword" href="forgotUsername">Forgot your Username?</a></div>
                    <div className="buttonWrapper">
                        <button type="submit" className="loginButton submitButton" name="submitSignin" onClick={() => loginUser()}>Login</button>
                    </div>
                </div>
            </>

        </div>
    );
}

export default LoginPage;
